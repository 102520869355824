.input {
  width: 100%;
  padding: .5em;
  border-bottom: 1px solid var(--border-color);
  outline: none;
  box-sizing: border-box;
  &:empty:not(:focus):before {
    content: attr(placeholder);
    color: var(--text-color);
    opacity: .3;
  }
  span {
    display: inline-block;
    box-sizing: border-box;
    &.autocomplete {
      color: lightblue;
    }
  }
}

ul.suggestions {
  position: absolute;
  margin-top: 1.5em;
  padding: 0;
  border: 1px solid var(--nimey-red);
  background-color: var(--text-color);
  li {
    padding: 0;
    position: relative;
    
    padding: 0 .25em;
    box-sizing: border-box;
    

    span {
      padding: 0.5em 0;
      display: inline-block;
      background-color: var(--text-color);
      color: var(--background-color);
    }
    .suggestion {
      color: var(--background-color);
      
    }
    &.active {
      font-weight: bold;
      .suggestion {
        font-weight: bold;
      }
    }
    
  }
}