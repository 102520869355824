.sidebar {
  width: 66%;
  max-width: 350px;
  height: 100%;
  border-left: 1px solid var(--border-color);
  box-shadow: 4px 0px 29px -6px var(--border-color);
  padding: 1em;
  box-sizing: border-box;
}

.background {
  display: none;
}

@media (max-width: 800px) {
  .sidebar {
    position: absolute;
    right: 0;
    bottom: 0;
    top: 0;
    margin-right: -100%;
    transition: margin-right .3s ease;
    background-color: var(--background-color);
  }
  .background {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: black;
    opacity: .4;
  }
  
  :global(body.sidebar-visible) {
    .sidebar {
      margin-right: 0;
    }

    .background {
      display: block;
    }
  }
}

.context-filter, .tag-filter {
    padding: 0;

    li {
      list-style: none;
      margin: .25em;
    }
}