.status-modal {
  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.status {
  :global(.nimey-modal) {
    :global(.inner) {
      background-color: var(--background-color);
      box-shadow: 0 0 15px 0px var(--text-color);
    }
  }
}