.footer {
  display: flex;
  align-items: center;
  border-top: 1px solid var(--border-color);
  justify-content: space-between;
  svg {
    height: 2em;
    width: auto;
    margin: .5em;
  }

  .actions {
    display: flex;
    gap: 1em;
    .account, .git {
      display: flex;
      align-items: center;
      svg {
        font-size: .5em;
      }
    }
  }

  .title {
    a {
      color: var(--text-color);
      text-decoration: none;
    }
  }
  .title, .theme-selector, .menu-trigger {
    display: flex;
    align-items: center;
    button {
      padding: 0;
    }
  }

  .theme-selector {
    padding: 0 .25em;
  }

  @media (min-width: 800px) {
    .menu-trigger {
      display: none;
    }
  }
}