body {
  --light-white: #fafafa;
  --light-black: #202020;
  --nimey-red: #550000;

  --context-default-background-color: rgb(185 185 185);
  --context-default-color: var(--light-black);
  --tag-default-background-color: var(--nimey-red);
  --tag-default-color: var(--light-white);
  --drop-zone-color: #cbcaca;
  --drop-zone-color-hover: var(--nimey-red);

  --dark-mode-toggle-background: #cbcaca;
  --dark-mode-toggle-color: var(--light-black);
}

:global(.dark) {
  --background-color: var(--light-black);
  --text-color: var(--light-white);
  --border-color: var(--light-white);

}

:global(.light) {
  --background-color: var(--light-white);
  --text-color: var(--light-black);
  --border-color: var(--light-black);
  --dark-mode-toggle-background: var(--light-black);
  --dark-mode-toggle-color: var(--light-white);
}

html, body, :global(#root), .app-wrapper {
  height: 100%;
}

.app-wrapper {
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--background-color);
  color: var(--text-color);
  transition: color .2s ease, background-color .2s ease;
}

