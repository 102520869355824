.todo-card {
  margin: .5em;
  padding: .5em;
  box-sizing: border-box;
  border: 1px solid var(--border-color);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  input {
    margin: 1em;
  }
}

.preview {
  position: absolute;
  width: auto;
  @media (max-width: 800px) {
    width: 100vw;
  }
}

