.page-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100%;
  overflow: auto;
  position: relative;
  overflow-x: hidden;

  .container {
    width: 700px;
    max-width: 90vw;

    border: 1px solid var(--text-color);
    box-shadow: 2px 2px 10px -3px var(--text-color);

    padding: 1em;
    box-sizing: border-box;
    border-radius: .5em;
  }
}

.select-todo-file .container, .select-repo .container {
  text-align: center;
  ul {
    padding: 0;
    li {
      display: block;
      margin: 0.5em;
      a {
        text-decoration: none;
      }
      button {
        width: 100%;
        text-align: left;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
}

.todo-file {
  display: flex;
  align-items: flex-start;
  .todo-renderer {
    width: 100%;
  }
}

.home {
  .container {
    text-align: center;
  }

  .footer-outer {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0
  }
}