.toggle {
  position: relative;
  input {
    visibility: hidden;
    position: absolute;
    width: 0;
    height: 0;
    overflow: hidden;
  }

  .inner {
    background-color: var(--dark-mode-toggle-background);
    display: flex;
    gap: 1em;
    justify-content: space-between;
    align-items: center;
    border-radius: 2em;
    font-size: .5em;
    color: var(--dark-mode-toggle-color);
    &::before {
      content: ' ';
      position: absolute;
      height: calc(100% - 10px);
      aspect-ratio: 1;
      background-color: var(--dark-mode-toggle-color);
      margin-left: 5px;
      border-radius: 100%;
      display: block;
      transition: margin-left .2s ease, color .2s ease;
    }
  }

  input:checked + .inner::before {
    background-color: var(--dark-mode-toggle-color);
    margin-left: calc(100% - 20px);
  }
}