.loading {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.inner {
  text-align: center;

  @keyframes dots {
    0% {
      content: ''
    }
    25% {
      content: ' .'
    }
    50% {
      content: ' ..'
    }
    75% {
      content: ' ...'
    }
    100% {
      content: ' ...'
    }
  }

  .childs {
    &::after {
      content: '';
      animation-name: dots;
      animation-duration: 4s;
      animation-iteration-count: infinite;
    }
  }

  @keyframes logo-fill-red {
    0% {
      fill: #550000;
    }
    50% {
      fill: #737373;
    }
    100% {
      fill: #550000;
    }
  }

  @keyframes logo-stroke-red {
    0% {
      stroke: #550000;
    }
    50% {
      stroke: #737373;
    }
    100% {
      stroke: #550000;
    }
  }

  @keyframes logo-fill-grey {
    0% {
      fill: #737373;
    }
    50% {
      fill: #550000;
    }
    100% {
      fill: #737373;
    }
  }

  @keyframes logo-stroke-grey {
    0% {
      stroke: #737373;
    }
    50% {
      stroke: #550000;
    }
    100% {
      stroke: #737373;
    }
  }

  :global(.nimey-red-stroke) {
    animation-name: logo-stroke-red;
    animation-duration: 7s;
    animation-iteration-count: infinite;
  }
  :global(.nimey-red-fill) {
    animation-name: logo-fill-red;
    animation-duration: 7s;
    animation-iteration-count: infinite;
  }
  :global(.nimey-grey-stroke) {
    animation-name: logo-stroke-grey;
    animation-duration: 7s;
    animation-iteration-count: infinite;
  }
  :global(.nimey-grey-fill) {
    animation-name: logo-fill-grey;
    animation-duration: 7s;
    animation-iteration-count: infinite;
  }
}