.word {
  display: inline-block;
  padding: 0.25em 0.5em;
  margin: 0 0.25em;
  border-radius: 1em;
}

.context {
  background-color: var(--context-default-background-color);
  color: var(--context-default-color);
}

.tag {
  background-color: var(--tag-default-background-color);
  color: var(--tag-default-color);
}